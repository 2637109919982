"use client";

import { ButtonProps } from "@chakra-ui/react";
import React from "react";
import { usePortalType, useRole } from "src/lib/hooks";
import { useLoginInfo } from "src/queries/emil/account";
import { useTranslatedString } from "../../i18n/hooks";
import { URLModalButton } from "../URLModal/URLModalButton";

type PremiumCalcButtonProps = {
	insuranceProductSlug?: string;
	targetGroupSlug?: string;
	enablePrefill?: boolean;
} & ButtonProps;

export const PremiumCalcButton: React.FC<PremiumCalcButtonProps> = ({
	insuranceProductSlug,
	targetGroupSlug,
	children,
	enablePrefill = true,
	...rest
}) => {
	const t = useTranslatedString();
	const loginInfo = useLoginInfo();
	const rolePermission = useRole();
	const portal = usePortalType();

	const params = enablePrefill
		? {
				insuranceProductSlug,
				targetGroupSlug,
				loginInfo,
				rolePermission,
				portal,
			}
		: undefined;

	return (
		<URLModalButton
			data-testid="premium-calc-cta"
			modalName="PremiumCalc"
			params={params}
			{...rest}
		>
			{children ? children : t("premiumCalc.calculate")}
		</URLModalButton>
	);
};

// 🔬 TBD: Please evaluate
