"use client";

import { chakra, useMultiStyleConfig } from "@chakra-ui/react";
import { useMeasure } from "@react-hookz/web";
import React from "react";
import { useInView } from "react-intersection-observer";
import { HeaderLinks } from "src/types";
import { AuthBasedHeader } from "./AuthHeader";
import { CMSMenuItems } from "./CMSMenuItems";
import { DesktopMenu } from "./DesktopMenu/DesktopMenu";
import { HeaderLogo } from "./Logo";
import { MobileMenu } from "./MobileMenu/MobileMenu";
import { Breadcrumbs, Crumb } from "../Breadcrumbs/Breadcrumbs";
import { DatoAssetData } from "../Dato/DatoAsset";
import { LanguageSwitch } from "../LanguageSwitch/LanguageSwitch";

export type HeaderProps = {
	variant?: "withTransition";
	logoData?: DatoAssetData;
	links?: HeaderLinks;
	children?: React.ReactNode;
	otherLanguages?: Array<{ locale: string; url: string }>;
	hasSiblingBrands?: boolean;
	breadcrumbsList?: Array<Crumb>;
};

const Menu: React.FC<Omit<HeaderProps, "links" | "otherLanguages">> = ({
	variant,
	children,
	logoData,
}) => {
	return (
		<>
			<DesktopMenu
				logo={
					<HeaderLogo
						analyticsId="click_header_logo_desktop"
						logoData={logoData}
						variant={variant}
					/>
				}
			>
				{children}
			</DesktopMenu>
			<MobileMenu
				logo={
					<HeaderLogo
						analyticsId="click_header_logo_mobile"
						logoData={logoData}
						variant={variant}
					/>
				}
			>
				{children}
			</MobileMenu>
		</>
	);
};

const Header: React.FC<HeaderProps> = ({
	logoData,
	links,
	children,
	variant,
	otherLanguages,
	breadcrumbsList,
	hasSiblingBrands = false,
}) => {
	const { ref: sentinelRef, inView: sentinelVisible } = useInView({
		initialInView: true,
		fallbackInView: false,
	});

	const styles = useMultiStyleConfig("Header", { variant });

	/**
	 * replace the header's height with a responsive replacement div without actually specifying
	 * the wrapper's height. Seems hacky, but it's infinitely better than a gazillion ternaries
	 * wrapped in media queries:
	 */

	const [measures, wrapperRef] = useMeasure<HTMLDivElement>();
	const headerHeight =
		// 116 is the current desktop height when measured, serves as a fallback here
		variant === "withTransition" ? "unset" : (measures?.height ?? 116);

	return (
		<chakra.header data-condition={sentinelVisible.toString()}>
			<chakra.div aria-hidden height={headerHeight} />
			<chakra.div __css={styles.wrapper} ref={wrapperRef}>
				<Menu logoData={logoData} variant={variant}>
					<CMSMenuItems items={links}>{children}</CMSMenuItems>
					<AuthBasedHeader />
					{hasSiblingBrands && (
						<LanguageSwitch languages={otherLanguages ?? []} />
					)}
				</Menu>
			</chakra.div>
			{/* We need some sort of sentinel element to track as the sticky element will always be visible  */}
			<chakra.div ref={sentinelRef} __css={styles.sentinel} />
			{breadcrumbsList && breadcrumbsList.length > 0 && (
				<Breadcrumbs breadcrumbsList={breadcrumbsList} />
			)}
		</chakra.header>
	);
};

export { Header };

// 🔬 TBD: Please evaluate
