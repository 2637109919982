import { useQueryClient } from "@tanstack/react-query";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { useRouter } from "next/navigation";
import { useCallback } from "react";
import { encodeUrlParams } from "react-url-modal";
import { useLogoutDateFromLocalStorage } from "src/components/Notification/hooks";
import { useEmilContext } from "./emil/context";

export const goToLoginPage = async (
	router: AppRouterInstance,
	loggedOut = true,
) => {
	const params = new URLSearchParams({
		modal: "Login",
		params: encodeUrlParams({ loggedOut }),
	}).toString();

	router.push(`/?${params}`);
};

export const goToHome = (router: AppRouterInstance) => {
	router.push(`/`);
};

export const useLogout = () => {
	const queryClient = useQueryClient();
	const { reset, authApi } = useEmilContext();
	const router = useRouter();

	const { setLogoutDate } = useLogoutDateFromLocalStorage();

	return useCallback(() => {
		setLogoutDate({
			logoutDate: new Date().toISOString(),
			fetched: false,
		});

		authApi.cleanTokenData();
		reset();
		queryClient.clear();
		goToLoginPage(router);
	}, [authApi, queryClient, reset, router, setLogoutDate]);
};

// 🔬 TBD: Please evaluate
