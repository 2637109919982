import {
	QueryClient,
	QueryClientProvider as ReactQueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useRouter } from "next/navigation";
import React, { ReactNode, useState } from "react";
import { useTranslatedString } from "src/i18n/hooks";
import { goToLoginPage } from "src/lib/auth";
import { isAuthError } from "src/lib/emil/utils";
import { useGenericErrorToast, useInfoToast } from "src/lib/toasts";

const MAX_RETRIES = 3;

// Retry any query/mutation 3 times, unless it's an auth problem
const retry = (attempts: number, error: unknown) =>
	!isAuthError(error) && attempts < MAX_RETRIES;

export const QueryClientProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const errorToast = useGenericErrorToast();
	const infoToast = useInfoToast();
	const t = useTranslatedString();
	const router = useRouter();

	const onError = async (error: unknown) => {
		// log out the user when it's related to auth timeout
		if (isAuthError(error)) {
			infoToast({
				description: t("toast.authTimeout"),
				id: "logged-out",
			});
			await goToLoginPage(router);
		} else {
			// not sure if there's a better way to type this
			errorToast(error as any);
		}
	};

	const [queryClient] = useState(
		new QueryClient({
			defaultOptions: {
				mutations: {
					onError,
				},
				queries: {
					onError,
					retry,
					// In the context of our app we attempt to manually invalidate changes.
					// This is why we use a generous stale time
					staleTime: 1000 * 60 * 60 * 24, // 24 hours
					// ...and increased cache time
					cacheTime: 1000 * 60 * 15, // 15 minutes
				},
			},
		}),
	);

	return (
		<ReactQueryClientProvider client={queryClient}>
			{children}
			<ReactQueryDevtools
				initialIsOpen={false}
				// Often, especially in Ladle, the button obscures key UI
				toggleButtonProps={{ style: { marginBottom: "80px" } }}
			/>
		</ReactQueryClientProvider>
	);
};

// 🔬 TBD: Please evaluate
