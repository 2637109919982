import { useCallback } from "react";
import {
	MessageDescriptor,
	useIntl,
	defineMessages,
	IntlFormatters,
} from "react-intl";
import { defaultMessages } from "src/__generated__/messages";
import { fromEntries } from "src/lib/utils";
import { OmitFirstArg } from "src/types";
import { MessageKey } from "./i18n";

const messages: Record<MessageKey, MessageDescriptor> = defineMessages(
	fromEntries(
		Object.entries(defaultMessages).map(([id, defaultMessage]) => [
			id,
			{
				id,
				defaultMessage,
			},
		]),
	),
);

export const useTranslatedString = () => {
	const intl = useIntl();
	const getMessage = useCallback(
		(
			id: MessageKey,
			...args: Parameters<OmitFirstArg<IntlFormatters["formatMessage"]>>
		) => {
			const descriptor = messages[id];

			return intl.formatMessage(descriptor, ...(args as any));
		},

		[intl],
	);

	return getMessage;
};

// 🔬 TBD
