import { z } from "zod";

export const isAuthError = (error: unknown) => {
	const parsed = z.object({ message: z.string().min(1) }).safeParse(error);

	if (parsed.success && parsed.data.message === "TOKEN_MISSING") return true;
	if (parsed.success && parsed.data.message === "Unauthorized") return true;

	return false;
};

export const productFieldTypes: Record<number, PremiumFactorType> = {
	1: "string",
	2: "boolean",
	3: "number",
	12: "dateTime",
};

export const getPremiumFactorTypeById = (id: number) =>
	productFieldTypes[id] ?? "string";

export const supportedPremiumFactorTypes = [
	"string",
	"boolean",
	"number",
	"dateTime",
	"options",
	"currency",
	"squareMeters",
	"gender",
	"csv",
] as const;

export type PremiumFactorType = (typeof supportedPremiumFactorTypes)[number];

// converts 0, 0 to A1, 1, 0 to B1, 0, 1 to A2, 1, 1 to B2, etc.
export const makeCellAddress = (column: number, row: number) => {
	const columns = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
	const lastLetter = columns[column % columns.length];
	const firstLetter = columns[Math.floor(column / columns.length) - 1];
	const columnLetter = firstLetter ? firstLetter + lastLetter : lastLetter;

	return `${columnLetter}${row + 1}`;
};

export const makeCellAddressRange = (
	column: number,
	row: number,
	width: number,
	height: number,
) => {
	const start = makeCellAddress(column, row);
	const end = makeCellAddress(column + width - 1, row + height - 1);

	return `${start}:${end}`;
};

export const tenantSlug = process.env.EMIL_TENANT_SLUG ?? "";

// 🔬 partially unit tested
