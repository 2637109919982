import { chakra, Spinner, useMultiStyleConfig } from "@chakra-ui/react";
import React from "react";
import { useTranslatedString } from "src/i18n/hooks";

type LoadingProps = {
	text?: string;
};

export const Loading: React.FC<LoadingProps> = ({ text }) => {
	const styles = useMultiStyleConfig("Loading");
	const t = useTranslatedString();

	return (
		<chakra.div __css={styles.wrapper}>
			<chakra.p __css={styles.text}>
				{text ?? t("loading.default")}
			</chakra.p>
			<chakra.div __css={styles.loadingWrapper}>
				<Spinner size="overlay" sx={styles.spinner} />
			</chakra.div>
		</chakra.div>
	);
};

export const LoadingDots: React.FC<
	LoadingProps & { variant?: "threeDots" | "fourDots" }
> = ({ text, variant = "threeDots" }) => {
	const styles = useMultiStyleConfig("Loading", { variant });
	const t = useTranslatedString();

	return (
		<chakra.div __css={styles.wrapper}>
			<chakra.div __css={styles.loadingWrapper}>
				<chakra.div sx={styles.dots} />
			</chakra.div>
			<chakra.p __css={styles.text}>
				{text ?? t("loading.default")}
			</chakra.p>
		</chakra.div>
	);
};

// 🔬 Story
