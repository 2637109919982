"use client";

import React from "react";
import { IntlProvider, ReactIntlErrorCode } from "react-intl";
import { BlogpostPageProps } from "src/lib/cms/blogpost";
import { SiteSettings } from "src/lib/cms/cms";
import { EmilProvider } from "src/lib/emil/context";
import {
	BlogpostContext,
	PortalTypeContext,
	ProductSlugContext,
	ProductsContext,
	RoleContext,
	SiteSettingsContext,
	TargetGroupContext,
} from "src/lib/hooks";
import { Role } from "./AuthGuard/utils";
import { QueryClientProvider } from "./QueryClientProvider";
import type {
	BrokerPortalSettings,
	PortalType,
	ProductSlug,
	TargetGroup,
} from "src/lib/ssr/utils";

type SharedContextProps = {
	siteSettings: SiteSettings;
	children: React.ReactNode;
	targetGroup?: TargetGroup;
	productSlug?: ProductSlug;
	rolePermission?: Role;
	blogpost?: NonNullable<BlogpostPageProps>["blogpost"];
	products?: BrokerPortalSettings["products"];
	portalType?: PortalType;
};

export const SharedContext: React.FC<SharedContextProps> = ({
	children,
	siteSettings,
	targetGroup,
	blogpost,
	productSlug,
	products,
	rolePermission,
	portalType,
}) => {
	return (
		<EmilProvider>
			<IntlProvider
				locale={siteSettings.locale}
				messages={siteSettings.translations}
				/**
				 * Reduce react-intl's "missing translation" error to a warning
				 */

				onError={(err) => {
					if (err.code === ReactIntlErrorCode.MISSING_TRANSLATION) {
						// eslint-disable-next-line no-console
						console.warn(err.message);

						return;
					}
					throw err;
				}}
			>
				<SiteSettingsContext.Provider value={siteSettings}>
					<PortalTypeContext.Provider value={portalType ?? null}>
						<TargetGroupContext.Provider
							value={targetGroup ?? null}
						>
							<ProductsContext.Provider value={products ?? null}>
								<ProductSlugContext.Provider
									value={productSlug ?? null}
								>
									<BlogpostContext.Provider
										value={blogpost ?? null}
									>
										<RoleContext.Provider
											value={rolePermission ?? null}
										>
											<QueryClientProvider>
												{children}
											</QueryClientProvider>
										</RoleContext.Provider>
									</BlogpostContext.Provider>
								</ProductSlugContext.Provider>
							</ProductsContext.Provider>
						</TargetGroupContext.Provider>
					</PortalTypeContext.Provider>
				</SiteSettingsContext.Provider>
			</IntlProvider>
		</EmilProvider>
	);
};

// 🔬 TBD: Please evaluate
