"use client";

// cf. https://github.com/chakra-ui/chakra-ui/issues/4396

import { Heading, HeadingProps } from "@chakra-ui/react";
import * as React from "react";
import { useContext, createContext, useMemo } from "react";
import { replacePipeWithShy } from "../../lib/replaceStringWithReactNode";

const headingLevels = ["h1", "h2", "h3", "h4", "h5", "h6"] as const;
const defaultLevel = "h1";

type HeadingLevel = (typeof headingLevels)[number];

const Context = createContext<HeadingLevel>(defaultLevel);

const useIncreasedContextLevel = (increase = 1) => {
	const levelFromContext = useContext(Context);

	return useMemo(() => {
		const index = headingLevels.indexOf(levelFromContext);
		const newIndex = index + increase;

		if (newIndex >= headingLevels.length) {
			return headingLevels[headingLevels.length - 1]; // cap at h6
		}

		return headingLevels[newIndex];
	}, [increase, levelFromContext]);
};

export const HeadingLevelBoundary: React.FC<{
	level?: HeadingLevel;
	children?: React.ReactNode;
}> = ({ level, children }) => {
	const increasedLevelFromContext = useIncreasedContextLevel(1);

	return (
		<Context.Provider
			value={level ?? increasedLevelFromContext ?? defaultLevel}
		>
			{children}
		</Context.Provider>
	);
};

export const Hx: React.FC<
	HeadingProps & { increment?: number; autoSize?: boolean }
> = ({
	children,
	as,
	size: sizeProp,
	autoSize = false,
	increment = 0,
	...props
}) => {
	const showDebuggingInfo = process.env.FEX_DEBUG_MODE;
	const asCtx = useIncreasedContextLevel(increment);
	const size = autoSize ? asCtx : sizeProp;

	const text = useMemo(
		// eslint-disable-next-line @typescript-eslint/promise-function-async
		() =>
			typeof children === "string"
				? replacePipeWithShy(children)
				: children,
		[children],
	);

	return (
		<Heading
			as={as ?? asCtx}
			size={size}
			{...props}
			data-styles={
				showDebuggingInfo
					? JSON.stringify({ sizeProp, autoSize, size })
					: undefined
			}
		>
			{text}
		</Heading>
	);
};

// 🔬 jest unit tested
