"use client";

import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";
import { openModal } from "react-url-modal";
import { ModalRouteProps, ModalRoutes } from "./modalRoutes";

export type URLModalButtonProps<
	ModalName extends keyof ModalRoutes = keyof ModalRoutes,
> = ButtonProps & {
	modalName: ModalName;
	params?: ModalRouteProps[ModalName];
};

export const URLModalButton = <ModalName extends keyof ModalRoutes>({
	modalName,
	params,
	children,
	...rest
}: URLModalButtonProps<ModalName>) => {
	return (
		<Button
			onClick={async () => openModal({ name: modalName, params })}
			{...rest}
		>
			{children}
		</Button>
	);
};

// 🔬 TBD: Please evaluate
