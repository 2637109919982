import { Divider, Link, useMultiStyleConfig } from "@chakra-ui/react";
import React, { ComponentProps, useMemo } from "react";
import { useTranslatedString } from "src/i18n/hooks";
import { ChevronUp, Globe } from "src/icons";
import { useSiteSettings } from "src/lib/hooks";
import { addDividerToHeaderMenuItems } from "../Header/CMSMenuItems";
import {
	HeaderAccordion,
	HeaderAccordionButton,
	HeaderAccordionContent,
} from "../HeaderAccordion/HeaderAccordion";
import {
	HeaderDropdown,
	HeaderDropdownButton,
	HeaderDropdownContent,
	HeaderDropdownItem,
} from "../HeaderDropdown/HeaderDropdown";
import { useHeaderDropdownContext } from "../HeaderDropdown/context";

export type LanguageSwitchProps = {
	languages: Array<{ locale: string; url: string }>;
};

type LanguageButtonProps = ComponentProps<typeof HeaderDropdownButton> & {
	isDisabled: boolean;
};

const LanguageButton: React.FC<LanguageButtonProps> = ({
	isDisabled,
	children,
	...buttonProps
}) => {
	const { isOpen } = useHeaderDropdownContext();
	const styles = useMultiStyleConfig("LanguageSwitch", { isOpen });

	return (
		<HeaderDropdownButton
			{...buttonProps}
			sx={styles.button}
			leftIcon={<Globe sx={styles.buttonIconLeft} />}
			rightIcon={<ChevronUp sx={styles.buttonIconRight} />}
			data-testid="lang-header-dropdown-button"
			isDisabled={isDisabled}
			display={{
				base: "none",
				desktopNavigation: "block",
			}}
		>
			{children}
		</HeaderDropdownButton>
	);
};

export const LanguageSwitch: React.FC<LanguageSwitchProps> = ({
	languages,
}) => {
	const styles = useMultiStyleConfig("LanguageSwitch");
	const t = useTranslatedString();
	const isDisabled = languages.length < 1;
	const { locale: currentLocale } = useSiteSettings();

	const buttonProps = useMemo(
		() => ({
			variant: "ghost",
			"aria-label": isDisabled
				? t("languageSwitch.disabledLabel")
				: t("languageSwitch.buttonLabel"),
			id: "language_switcher",
		}),
		[isDisabled, t],
	);

	const items = useMemo(
		() =>
			[{ locale: currentLocale, url: "#" }, ...languages].map(
				({ locale, url }) => {
					const fullName = new Intl.DisplayNames([locale], {
						type: "language",
					}).of(locale);

					return {
						key: url,
						fullName,
						label: (
							<>
								<Globe sx={styles.itemIcon} /> {fullName}
							</>
						),
						sx: styles.item,
						href: url,
						id: `language_switcher_item_${locale}`,
					};
				},
			),
		[currentLocale, languages, styles.item, styles.itemIcon],
	);

	return (
		<>
			<HeaderDropdown openOnHover={false}>
				<LanguageButton isDisabled={isDisabled} {...buttonProps}>
					{currentLocale}
				</LanguageButton>
				<HeaderDropdownContent>
					{items.map(({ key, id, fullName: _, ...props }) => {
						return (
							<HeaderDropdownItem
								key={key}
								isExternal={true}
								{...props}
								id={`${id}_desktop`}
							/>
						);
					})}
				</HeaderDropdownContent>
			</HeaderDropdown>

			<HeaderAccordion
				display={{
					base: "block",
					desktopNavigation: "none",
				}}
			>
				<HeaderAccordionButton
					{...buttonProps}
					sx={styles.dropdownButton}
				>
					<Globe sx={styles.dropdownButtonIcon} />
					{currentLocale}
				</HeaderAccordionButton>
				<HeaderAccordionContent>
					{items
						.map(({ fullName, key, id, ...props }) => {
							return (
								<Link
									variant="headerDropdown"
									key={key}
									{...props}
									id={`${id}_mobile`}
								>
									<Globe sx={styles.dropdownButtonIcon} />
									{fullName}
								</Link>
							);
						})
						.flatMap(
							addDividerToHeaderMenuItems(
								<Divider
									display={{
										base: "block",
										desktopNavigation: "none",
									}}
								/>,
							),
						)}
				</HeaderAccordionContent>
			</HeaderAccordion>
		</>
	);
};

// 🔬 TBD: e2e
