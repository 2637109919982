import {
	ButtonProps,
	useMultiStyleConfig,
	VisuallyHidden,
	chakra,
} from "@chakra-ui/react";
import React from "react";
import { useTranslatedString } from "src/i18n/hooks";

type BurgerButtonProps = { isOpen: boolean } & ButtonProps;

export const BurgerButton: React.FC<BurgerButtonProps> = ({
	isOpen,
	className,
	...rest
}) => {
	const styles = useMultiStyleConfig("BurgerButton", {
		isOpen,
	});
	const t = useTranslatedString();

	return (
		<chakra.button
			{...rest}
			__css={styles.hamburger}
			className={isOpen ? "is-active" : ""}
		>
			<chakra.span __css={styles.hamburgerBox} aria-hidden="true">
				<chakra.span __css={styles.hamburgerInner} />
			</chakra.span>
			<VisuallyHidden>{t("menu")}</VisuallyHidden>
		</chakra.button>
	);
};

// 🔬 TBD: Please evaluate
