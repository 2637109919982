import {
	Breadcrumb as ChakraBreadcrumb,
	BreadcrumbProps as ChakraBreadcrumbProps,
	BreadcrumbItem as ChakraBreadcrumbItem,
	BreadcrumbLink as ChakraBreadcrumbLink,
	chakra,
	useMultiStyleConfig,
	VisuallyHidden,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { useTranslatedString } from "src/i18n/hooks";
import { ChevronUp, Home } from "src/icons";
import { replacePipeWithShy } from "src/lib/replaceStringWithReactNode";
import { createBreadcrumbs } from "src/lib/utils";

export type Crumb = ReturnType<typeof createBreadcrumbs>[number];

export type BreadcrumbsProps = {
	breadcrumbsList: Array<Crumb>;
};

const BreadcrumbItem: React.FC<{
	name: ReactNode;
	href: string;
	isCurrentPage: boolean;
}> = ({ name, href, isCurrentPage, ...rest }) => {
	const styles = useMultiStyleConfig("Breadcrumbs");

	return (
		<ChakraBreadcrumbItem
			isCurrentPage={isCurrentPage}
			sx={styles.breadcrumb}
			{...rest}
		>
			<ChakraBreadcrumbLink sx={styles.breadcrumbLink} href={href}>
				{name}
			</ChakraBreadcrumbLink>
		</ChakraBreadcrumbItem>
	);
};

const Breadcrumb: React.FC<ChakraBreadcrumbProps> = ({ children, ...rest }) => {
	const styles = useMultiStyleConfig("Breadcrumbs");

	return (
		<ChakraBreadcrumb
			spacing={3}
			separator={<ChevronUp sx={styles.chevronIcon} />}
			{...rest}
		>
			{children}
		</ChakraBreadcrumb>
	);
};

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
	breadcrumbsList,
}) => {
	const t = useTranslatedString();
	const styles = useMultiStyleConfig("Breadcrumbs");

	return (
		<chakra.div __css={styles.wrapper}>
			<Breadcrumb>
				<BreadcrumbItem
					key="index"
					href="/"
					name={
						<>
							<VisuallyHidden>
								{t("breadcrumbs.home")}
							</VisuallyHidden>
							<Home sx={styles.homeIcon} />
						</>
					}
					isCurrentPage={false}
				/>
				{breadcrumbsList.map(({ name }, index) => {
					const href = `/${breadcrumbsList
						.map(({ slug }) => slug)
						.slice(0, index + 1)
						.join("/")}`;

					return (
						<BreadcrumbItem
							key={href}
							href={href}
							name={replacePipeWithShy(name)}
							isCurrentPage={breadcrumbsList.length - 1 === index}
						/>
					);
				})}
			</Breadcrumb>
		</chakra.div>
	);
};

// 🔬 e2e: breadcrumbs.spec.ts
